<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Vehículos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Vehículos</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                        v-if="$store.getters.can('admin.vehiculos.pivotTable')"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_vehiculo_export"
                        v-if="$store.getters.can('admin.vehiculos.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('admin.vehiculos.create')"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Placa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.placa"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Odometro
                      </th>
                      <th>
                        Líneas Negocio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="linea_negocio"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.lineas_negocio"
                          :filterable="true"
                          multiple
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.selct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Marca
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="marca"
                          placeholder="Marca"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.marcas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Modelo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.modelo"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Combustible
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="combustible"
                          placeholder="Combustible"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.combustibles"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Tipo Vehículo
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="tipo_vehiculo"
                          placeholder="Tipo Vehículo"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.tipos_vehiculos"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th >Estado Documental</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>

                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="vehiculo in vehiculos.data" :key="vehiculo.id">
                      <td>
                        <a
                          :href="uri_docs + vehiculo.link_fotografia"
                          target="_blank"
                        >
                          <img
                            :src="uri_docs + vehiculo.link_fotografia"
                            alt="Vehiculo"
                            width="75"
                            class="rounded mx-auto d-block"
                          />
                        </a>
                      </td>
                      <td>{{ vehiculo.placa }}</td>
                      <td>{{ vehiculo.odometro }}</td>
                      <td class="text-center">
                        <div
                          v-for="lineas in vehiculo.lineas_negocio"
                          :key="lineas.id"
                        >
                          <span class="badge bg-navy">
                            {{ lineas.nombre }}
                          </span>
                        </div>
                      </td>
                      <td>
                        {{
                          vehiculo.empresa != null
                            ? vehiculo.empresa.razon_social
                            : "No encontrada"
                        }}
                      </td>
                      <td>{{ vehiculo.nMarca }}</td>
                      <td>{{ vehiculo.modelo }}</td>
                      <td>{{ vehiculo.nCombustible }}</td>
                      <td>
                        {{
                          vehiculo.tipo_vehiculo_id
                            ? vehiculo.tipo_vehiculo.nombre
                            : ""
                        }}
                      </td>
                      <td class="col-md-1">
                        
                          <div class="col-md-12">
                            <span
                              v-if="vehiculo.documentos_vencidos.length > 0"
                              class="badge badge-danger"
                            >
                              Documentos vencidos ({{
                                vehiculo.documentos_vencidos.length
                              }})
                            </span>
                            <span
                              v-if="vehiculo.paquetes.length <= 0"
                              class="badge badge-danger"
                            >
                              Sin paquete Documental
                            </span>

                            <span
                              v-if="vehiculo.documentos_pendientes.length > 0"
                              class="badge badge-warning"
                            >
                              Documentos pendientes ({{
                                vehiculo.documentos_pendientes.length
                              }})
                            </span>

                            <span
                              v-if="
                                vehiculo.documentos_no_verificados.length > 0
                              "
                              class="badge badge-info"
                            >
                              Documentos no verificados ({{
                                vehiculo.documentos_no_verificados.length
                              }})
                            </span>

                            <span
                              v-if="
                                vehiculo.documentos_vencidos.length === 0 &&
                                  vehiculo.documentos_pendientes.length === 0 &&
                                  vehiculo.documentos_no_verificados.length ===
                                  0 && vehiculo.paquetes.length > 0
                              "
                              class="badge badge-success"
                            >
                              Verificado
                            </span>
                          </div>
                        
                      </td>
                      <td style="width: 85px" align="right">
                        <div class="row">
                          <div class="col-md-6">
                            <span
                              class="badge"
                              data-toggle="modal"
                              data-target="#modal-form-estado"
                              style="cursor: pointer"
                              v-bind:class="[
                                vehiculo.estado == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                              @click="llenarModal(vehiculo.id, vehiculo.estado)"
                            >
                              {{ vehiculo.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                            </span>
                          </div>
                          <div class="col-md-6">
                            <i
                              class="fas fa-eye"
                              data-toggle="modal"
                              data-target="#modal-historial-estado"
                              style="cursor: pointer"
                              v-if="$store.getters.can('admin.vehiculos.show')"
                              @click="
                                llenarModalHistorial(
                                  'App\\Vehiculo',
                                  vehiculo.id
                                )
                              "
                            ></i>
                          </div>
                        </div>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <div
                            v-if="
                              $store.getters.can(
                                'admin.vehiculos.editarTransportadora'
                              ) && !admin
                            "
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="edit(vehiculo, 3)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.vehiculos.show')"
                              @click="edit(vehiculo, 1)"
                            >
                              <i class="fas fa-eye"></i>
                            </button>
                          </div>
                          <div v-if="empresa.length > 0">
                            <div v-if="vehiculo.tipo_propiedad == 1">
                              <div v-if="vehiculo.empresa_id">
                                <div>
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    v-if="
                                      $store.getters.can(
                                        'admin.vehiculos.edit'
                                      ) && empresa[0].id == vehiculo.empresa_id
                                    "
                                    @click="edit(vehiculo, 2)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                v-if="
                                  $store.getters.can('admin.vehiculos.edit')
                                "
                                @click="edit(vehiculo, 2)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.vehiculos.edit')"
                              @click="edit(vehiculo, 2)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="vehiculos.total">
                  <p>
                    Mostrando del <b>{{ vehiculos.from }}</b> al
                    <b>{{ vehiculos.to }}</b> de un total:
                    <b>{{ vehiculos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="vehiculos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <VehiculoEstado ref="VehiculoEstado" />
        <HistorialEstado ref="HistorialEstado" />
      </div>
    </div>
    <VehiculoExport ref="VehiculoExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import VehiculoEstado from "./VehiculoEstado";
import HistorialEstado from "../historialEstado/HistorialEstado";
import VehiculoExport from "./VehiculoExport";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";

export default {
  name: "VehiculoIndex",
  components: {
    pagination,
    VehiculoEstado,
    VehiculoExport,
    HistorialEstado,
    vSelect,
    Loading,
  },
  data() {
    return {
      linea_negocio: [],
      empresas: [],
      empresa: this.$store.getters.getUser.empresas,
      tipo_vehiculo: [],
      user: null,
      admin: null,
      cargando: false,
      filtros: {
        placa: null,
        empresa: null,
        empresa_id: null,
        estado: null,
        modelo: null,
        marca: null,
        marca_id: null,
        combustible: null,
        combustible_id: null,
        tipo_vehiculo_id: null,
      },
      vehiculos: {},
      listasForms: {
        empresas: [],
        tiposCarrocerias: [],
        estados: [],
        lineas_negocio: [],
        selct_empresa: [],
        marcas: [],
        combustibles: [],
        tipos_vehiculos: [],
      },
      combustible: null,
      marca: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {},
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.lineas_negocio = [];
      this.filtros.empresa_id = null;
      this.filtros.marca_id = null;
      this.filtros.combustible_id = null;
      this.linea_negocio.forEach((linea) => {
        this.filtros.lineas_negocio.push(linea.id);
      });
      if (this.listasForms.selct_empresa) {
        this.filtros.empresa_id = this.listasForms.selct_empresa.id;
      }
      if (this.marca) {
        this.filtros.marca_id = this.marca.numeracion;
      }
      if (this.combustible) {
        this.filtros.combustible_id = this.combustible.numeracion;
      }
      if (this.tipo_vehiculo) {
        this.filtros.tipo_vehiculo_id = this.tipo_vehiculo.id;
      }
      axios
        .get("/api/admin/vehiculos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.vehiculos = response.data;
          this.cargando = false;
        });

      if (this.$store.state.user) {
        const administrador = this.$store.state.user.roles.find(
          (administrador) => administrador.id === 1
        );
        if (administrador) {
          this.admin = administrador.id;
        }
      }
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getTiposCarrocerias() {
      axios.get("/api/lista/9").then((response) => {
        this.listasForms.tiposCarrocerias = response.data;
      });
    },

    getMarcas() {
      axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    getTiposCombustible() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.combustibles = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getTiposVehiculos() {
      await axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipos_vehiculos = response.data;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idVehiculo) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/vehiculos/" + idVehiculo)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el vehículo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/VehiculoForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(vehiculo, opcion) {
      return this.$router.push({
        name: "/Admin/VehiculoForm",
        params: {
          accion: "Editar",
          data_edit: vehiculo,
          id: vehiculo.id,
          opcion: opcion,
        },
      });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Admin/VehiculoPivotTable",
      });
    },
    llenarModal(id, estado) {
      if (this.$store.getters.can("admin.vehiculos.editStatus")) {
        this.$refs.VehiculoEstado.llenar_modal(id, estado);
      }
    },

    llenarModalHistorial(entidad, entidad_id) {
      if (this.$store.getters.can("admin.vehiculos.show")) {
        this.$refs.HistorialEstado.llenar_modal(entidad, entidad_id);
      }
    },
    generarListadoExcel() {
      this.$parent.cargando = true;

      axios({
        method: "POST",
        url: "/api/admin/vehiculos/export",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
  mounted() {
    this.getIndex();
    this.getEmpresas();
    this.getTiposCarrocerias();
    this.getEstados();
    this.getLineasNegocio();
    this.getMarcas();
    this.getTiposCombustible();
    this.getTiposVehiculos();
  },
};
</script>
